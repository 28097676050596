import React, { CSSProperties, useEffect, useRef, useState } from "react"
import KeenSlider, { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { graphql, useStaticQuery } from "gatsby"
import {
  Fei,
  Innovatum,
  Ojity,
  ArrowRight,
  ArrowLeft,
  EkFriends,
  Aurobay,
  InhouseTech,
  Systemweaver,
} from "./svg"
import Img from "gatsby-image/withIEPolyfill"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import useVisibility from "utils/useVisibility"

declare global {
  interface Window {
    // TODO: replace this with a more specific type based on usage
    dataLayer: any[]
  }
}

const NextArrow = props => (
  <button
    className={props.className}
    onClick={props.onClick}
    title={"Next slide"}
  >
    <ArrowRight className="svg-white" style={{ width: "50px" }} />
  </button>
)
const PrevArrow = props => (
  <button
    className={props.className}
    onClick={props.onClick}
    title={"Prev slide"}
  >
    <ArrowLeft className="svg-white" style={{ width: "50px" }} />
  </button>
)

const imgWrapper: CSSProperties = {
  position: "absolute",
  zIndex: 0,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  opacity: 0.75,
}

const Slider = () => {
  const [isVisible, ref] = useVisibility<HTMLElement>(0, 50, true)

  const {
    innovatum,
    fei,
    ojity,
    ekfriends,
    aurobay,
    systemweaver,
    inhousetech,
  } = useStaticQuery(graphql`
    query CaseSliderQuery {
      innovatum: imageSharp(
        sizes: { originalName: { eq: "innovatum-img.jpg" } }
      ) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      fei: imageSharp(sizes: { originalName: { eq: "fei-img.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      ojity: imageSharp(sizes: { originalName: { eq: "ojity-bg.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      ekfriends: imageSharp(sizes: { originalName: { eq: "ek-img.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      aurobay: imageSharp(sizes: { originalName: { eq: "aurobay-bg.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      systemweaver: imageSharp(sizes: { originalName: { eq: "sw-bg.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      inhousetech: imageSharp(
        sizes: { originalName: { eq: "inhousetech-bg.jpg" } }
      ) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `)

  const { t } = useTranslation()

  const slides = [
    {
      id: 5,
      image: aurobay,
      logo: <Aurobay style={{ width: "100%" }} />,
      relationship: <Trans>Client</Trans>,
      year: `2021 – ${t("ongoing")}`,
      titles: [
        <Trans key="5-1">Web development</Trans>,
        <Trans key="5-3">Backend infrastructure</Trans>,
        <Trans key="5-2">Maintenance</Trans>,
      ],
      paragraph: <Trans>aurobayText</Trans>,
      link: {
        href: "https://links.mlkr.io/aurobay-7qt",
        title: "aurobay.com",
      },
    },
    {
      id: 7,
      image: systemweaver,
      logo: <Systemweaver style={{ width: "100%" }} />,
      relationship: <Trans>Client</Trans>,
      year: `2022`,
      titles: [
        <Trans key="5-1">Web development</Trans>,
        <Trans key="5-3">Backend infrastructure</Trans>,
      ],
      paragraph: <Trans>systemweaverText</Trans>,
      link: {
        href: "https://systemweaver.com",
        title: "systemweaver.com",
      },
    },
    {
      id: 2,
      image: fei,
      logo: <Fei className="svg-fill-white" style={{ width: "100%" }} />,
      relationship: <Trans>Client</Trans>,
      year: `2017 – ${t("ongoing")}`,
      titles: [
        <Trans key="2-3">E-commerce</Trans>,
        <Trans key="2-1">Systems integration</Trans>,
        <Trans key="2-2">Process automation</Trans>,
      ],
      paragraph: <Trans>feiText</Trans>,
      link: {
        href: "https://links.mlkr.io/fei-91a26",
        title: "fei.se",
      },
    },
    {
      id: 6,
      image: inhousetech,
      logo: (
        <InhouseTech className="svg-fill-white" style={{ width: "100%" }} />
      ),
      relationship: <Trans>Client</Trans>,
      year: `2022`,
      titles: [<Trans key="8-3">Web development</Trans>],
      paragraph: <Trans>inhousetechText</Trans>,
      link: {
        href: "https://links.mlkr.io/inhousetech-6eb",
        title: "inhousetech.se",
      },
    },
    {
      id: 1,
      image: innovatum,
      logo: <Innovatum className="svg-fill-white" style={{ width: "100%" }} />,
      relationship: <Trans>Client</Trans>,
      year: `2021 – ${t("ongoing")}`,
      titles: [
        <Trans key="1-1">Web development</Trans>,
        <Trans key="1-2">Backend infrastructure</Trans>,
        <Trans key="1-3">Maintenance</Trans>,
      ],
      paragraph: <Trans>innovatumText</Trans>,
      link: {
        href: "https://innovatumsciencepark.se/",
        title: "innovatumsciencepark.se",
      },
    },
    {
      id: 3,
      image: ojity,
      logo: <Ojity className="svg-fill-white" style={{ width: "100%" }} />,
      relationship: (
        <>
          {"Partner, "}
          <Trans>Client</Trans>
        </>
      ),
      year: `2019 – ${t("ongoing")}`,
      titles: [
        <Trans key="3-1">Technical consultancy</Trans>,
        <Trans key="3-2">Software development</Trans>,
        <Trans key="3-3">Maintenance</Trans>,
      ],
      paragraph: <Trans>ojityText</Trans>,
      link: {
        href: "https://ojity.com/",
        title: "ojity.com",
      },
    },
    {
      id: 4,
      image: ekfriends,
      logo: <EkFriends style={{ width: "100%" }} />,
      relationship: <Trans>Client</Trans>,
      year: `2018 – ${t("ongoing")}`,
      titles: [
        <Trans key="9-2">Process automation</Trans>,
        <Trans key="9-1">Web development</Trans>,
        <Trans key="9-3">Design</Trans>,
      ],
      paragraph: <Trans>ekfriendsText</Trans>,
      link: {
        href: "https://ekfriends.se/",
        title: "ekfriends.se",
      },
    },
  ]

  const [details, setDetails] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    duration: 1000,
    initial: 0,
    slideChanged(s: KeenSlider) {
      setCurrentSlide(s.details().relativeSlide)
    },
    dragStart() {
      if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push({
          event: "Case interaction",
        })
      }
    },
    move: (s: KeenSlider) => {
      setDetails(s.details())
    },
  })

  const positionStyle = idx => {
    if (!details) {
      return {}
    }
    const position = details.positions[idx]
    const x = details.widthOrHeight * position.distance * -0.75

    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }
  const opacityStyle = idx => {
    if (!details) {
      return {}
    }
    const position = details.positions[idx]
    const opacity_size = 0.8
    const opacity = 1 - (opacity_size - opacity_size * position.portion)

    return {
      opacity: opacity,
    }
  }
  const scaleStyle = idx => {
    if (!details) {
      return {}
    }
    const position = details.positions[idx]
    const x = details.widthOrHeight * position.distance * -0.65
    const scale_size = 0
    const scale = 1 - (scale_size - scale_size * position.portion)

    return {
      transform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px) scale(${scale})`,
    }
  }

  return (
    <section
      id={t("colabs")}
      ref={ref}
      style={{ width: "100%", position: "relative" }}
    >
      <div ref={sliderRef} className="keen-slider bg-black">
        {slides.map((s, idx) => (
          <div
            key={s.id}
            style={opacityStyle(idx)}
            className="keen-slider__slide sm:p-5 sm:pb-10 md:p-10 md:pb-20"
          >
            <div
              className="frosted slide-content pb-10 md:pb-0 z-20 w-full h-full flex items-start md:items-center justify-center"
              style={{
                maxWidth: "1200px",
                margin: "0 auto",
                ...scaleStyle(idx),
              }}
            >
              <div className="py-8 md:py-20 container">
                <div className="row">
                  <div className="flex flex-col items-start justify-center col-span-8  col-start-1 sm:col-span-6 sm:col-start-2 md:col-span-4 md:col-start-1 lg:col-span-4 lg:col-start-2 xl:col-span-3 xl:col-start-3 mb-16 md:mb-0">
                    <div className="w-full mt-8 md:mt-0">{s.logo}</div>
                    <div className="hidden md:grid grid-cols-12 text-sm mt-8 w-full">
                      <div className="col-span-4 col-start-1 sm:col-span-3 md:col-span-5 sm:col-start-1 lowercase opacity-60">
                        <Trans>colab</Trans>:
                      </div>
                      <div className="col-span-6">{s.relationship}</div>
                      <div className="col-span-4 col-start-1 sm:col-span-3 md:col-span-5 sm:col-start-1 lowercase opacity-60">
                        <Trans>period</Trans>:
                      </div>
                      <div className="col-span-6">{s.year}</div>
                      <div className="col-span-4 col-start-1 sm:col-span-3 md:col-span-5 sm:col-start-1 lowercase opacity-60">
                        <Trans>website</Trans>:
                      </div>
                      <div className="col-span-6">
                        <a
                          href={s.link.href}
                          className="text-pink"
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          {s.link.title}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center col-span-12 sm:col-span-10 sm:col-start-2 md:col-span-7 md:col-start-6 lg:col-span-6 lg:col-start-7">
                    {s.titles.map(title => (
                      <h3 key={title.key} className="styled mb-3">
                        {title}
                      </h3>
                    ))}
                    <hr className="mb-5 mt-2 opacity-20" />
                    <p>{s.paragraph}</p>
                    <div className="grid md:hidden grid-cols-12 text-sm mt-8 w-full">
                      <div className="col-span-3 col-start-1 sm:col-span-3 md:col-span-5 sm:col-start-1 lowercase opacity-60">
                        <Trans>colab</Trans>:
                      </div>
                      <div className="col-span-6">{s.relationship}</div>
                      <div className="col-span-3 col-start-1 sm:col-span-3 md:col-span-5 sm:col-start-1 lowercase opacity-60">
                        <Trans>period</Trans>:
                      </div>
                      <div className="col-span-6">{s.year}</div>
                      <div className="col-span-3 col-start-1 sm:col-span-3 md:col-span-5 sm:col-start-1 lowercase opacity-60">
                        <Trans>website</Trans>:
                      </div>
                      <div className="col-span-6">
                        <a
                          href={s.link.href}
                          className="text-pink"
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          {s.link.title}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="slide-image"
              style={{ ...imgWrapper, ...positionStyle(idx) }}
            >
              <Img
                fluid={{ ...s.image.fluid }}
                loading={"lazy"}
                objectFit={"cover"}
                style={{ height: "100%" }}
                objectPosition={"middle"}
              />
            </div>
          </div>
        ))}
        {slider && (
          <>
            <PrevArrow
              className="slide-button slide-button__prev"
              onClick={e => {
                e.stopPropagation()
                slider.prev()
              }}
              disabled={currentSlide === 0}
            />
            <NextArrow
              className="slide-button slide-button__next"
              onClick={e => {
                e.stopPropagation()
                slider.next()
              }}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
        {slider && (
          <div className="dots">
            {[...Array(slider.details().size).keys()].map(idx => {
              return (
                <button
                  key={idx}
                  title={"Slide"}
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                />
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default Slider
