import { createRef, RefObject, useCallback, useEffect, useState } from "react";
import { throttle } from "utils/helpers";

export default function useVisibility<Element extends HTMLElement>(
  offset: number = 0,
  throttleMilliseconds: number = 100,
  once: boolean = false
): [Boolean, RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = createRef<Element>();

  const onScroll = useCallback(throttle(() => {
    if (once && isVisible) {
      return;
    }
    if (!currentElement.current) {
      setIsVisible(false);

      return;
    }
    const {top, bottom} = currentElement.current.getBoundingClientRect();
    
    setIsVisible(top + offset - window.innerHeight <= 0 && bottom >= 0);
  }, throttleMilliseconds), [currentElement, isVisible]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [currentElement, isVisible]);

  return [isVisible, currentElement];
}
