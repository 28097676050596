import React, { useEffect, useState } from "react";
import { useTransition, animated, config } from "react-spring/web.cjs";

interface Props {
  children: string
}

const TitleAnimation = ({children}: Props) => {
  const textArr =  /\[\[(.*)\]\]/.exec(children);
  const texts = textArr[1].split(',');
  
  const [textNo, setTextNo] = useState(0);

  const mainText = children.replace(/\[\[.*\]\]/, '[[]]').split('[[]]');

  const transitions = useTransition(textNo, null, {
    from: {opacity: 0, position: 'absolute', transform: 'translate3d(-100px,0,0)' },
    enter: { opacity: 1, position: 'relative', transform: 'translate3d(0,0,0)' },
    leave: {opacity: 0, position: 'absolute', transform: 'translate3d(50px,0,0)' },
    config: config.stiff
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTextNo(state => {
        const n = (state + 1) % texts.length;
        if (n === (texts.length - 1)) {
          clearInterval(interval);
        }
        
        return n;
      });
      
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  
  return (
    <h1 className="m-0 overflow-visible">
      {mainText[0]}
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.div className="styled-text" style={props} key={key}>{texts[item]}</animated.div>
        );
      })}
      {mainText[1]}
    </h1>
  )
}

export default TitleAnimation;
