import React, { useState } from "react"
import Layout from "components/layout"
import { graphql } from "gatsby"
import ContactForm, {
  Type,
  CompanyName,
  Name,
  Email,
  Other,
} from "components/contact-form"
import Slider from "components/slider"
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next"
import StartHero from "components/start-hero"
import { submitForm } from "utils/helpers"

declare global {
  interface Window {
    // TODO: replace this with a more specific type based on usage
    dataLayer: any[]
  }
}

const IndexPage = ({ location, data }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const [isSubmitted, setSubmitted] = useState<boolean>(false)

  return (
    <Layout path={location.pathname}>
      <StartHero />
      <section id={t("contact")} className="bg-white pt-0 pb-24 md:py-32">
        <div className="container">
          <div className="row">
            <div className="text-smokeDarkBlack flex flex-col justify-center col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
              <h2 className="font-bold uppercase mt-10 md:mt-0 mb-7">
                {t("title")}
              </h2>
              <p className="mb-5">{t("text")}</p>
              {isSubmitted ? (
                <h3>{t("Thank you")}!</h3>
              ) : (
                <ContactForm
                  formName={"Contact form - start page"}
                  onSubmit={values => {
                    submitForm(values).then(() => {
                      setSubmitted(true)

                      if (typeof window.dataLayer !== "undefined") {
                        window.dataLayer.push({
                          event: "Home page form submitted",
                        })
                      }
                    })
                  }}
                  pages={[
                    props => <Type {...props} />,
                    props => <CompanyName {...props} />,
                    props => <Name {...props} />,
                    props => <Email {...props} />,
                    props => <Other {...props} />,
                  ]}
                  initFormFields={{
                    type: "",
                    company: "",
                    name: "",
                    email: "",
                    message: "",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <Slider />
      <section id={t("mission")} className="py-20 md:py-32">
        <div className="container">
          <div className="row offer">
            <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-2">
              <h2 className="font-bold uppercase mb-7">
                <Trans>Mission</Trans>
              </h2>
              <h3 className="ingress font-extralight pb-4">
                {t("missionIngress")}
              </h3>
              <p>{t("missionPar1")}</p>
              <p>{t("missionPar2")}</p>
              <p className="mt-14">
                <a href={`#${t("contact")}`} className="contact-button">
                  <Trans>Contact us</Trans>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id={t("services")}
        className="services-section bg-smokeBgBlack text-white py-20 md:py-32"
      >
        <div className="container">
          <div className="row offer py-10">
            <div className="col-span-12">
              <h2 className="font-bold uppercase mb-7">
                <Trans>Our services</Trans>
              </h2>
            </div>
            <div className="col-span-12 sm:col-span-5 xl:col-span-4 mb-7">
              <h3 className="font-bold service-heading">
                <Trans>software development</Trans>
              </h3>
              <ul>
                <li>
                  <Trans>E-commerce</Trans>
                </li>
                <li>
                  <Trans>Web development</Trans>
                </li>
                <li>
                  <Trans>Bespoke systems</Trans> *
                </li>
                <li>
                  <Trans>Application development</Trans> *
                </li>
              </ul>
            </div>
            <div className="col-span-12 sm:col-span-5 sm:col-start-8 xl:col-span-4 xl:col-start-8 mb-7">
              <h3 className="font-bold service-heading">
                <Trans>business support</Trans>
              </h3>
              <ul>
                <li>
                  <Trans>Digitalising business processes</Trans>
                </li>
                <li>
                  <Trans>Process automation</Trans>
                </li>
                <li>
                  <Trans>Data management</Trans>
                </li>
                <li>
                  <Trans>Digital marketing</Trans> *
                </li>
              </ul>
            </div>
            <div className="col-span-12 sm:col-span-5 xl:col-span-4 mb-7">
              <h3 className="font-bold service-heading">
                <Trans>design</Trans>
              </h3>
              <ul>
                <li>
                  <Trans>User experience</Trans>
                </li>
                <li>
                  <Trans>User interface</Trans> *
                </li>
                <li>
                  <Trans>Brand development</Trans> *
                </li>
              </ul>
            </div>
            <div className="col-span-12 sm:col-span-5 sm:col-start-8 xl:col-span-4 xl:col-start-8 mb-7">
              <h3 className="font-bold service-heading">
                <Trans>project management</Trans>
              </h3>
              <ul>
                <li>
                  <Trans>Agile development</Trans>
                </li>
                <li>
                  <Trans>Specification solicitation</Trans>
                </li>
                <li>
                  <Trans>Project planning</Trans>
                </li>
                <li>
                  <Trans>Technical budgeting</Trans>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-span-12 mb-8">
              <small>
                * <Trans>in colab with trusted partners</Trans>
              </small>
            </div>
          </div>
          <div className="row">
            {language === "sv" ? (
              <div className="col-span-12 md:col-span-10 xl:col-span-6">
                Är du själv skarp på något av områdena ovanför? Släng ett öga på
                vår <Link to={"/career"}>karriärsida</Link> och skicka över ett
                meddelande!
              </div>
            ) : (
              <div className="col-span-12 md:col-span-10 xl:col-span-6">
                Are you an expert in any of the services above? Make sure to
                have look at our <Link to={"/career"}>career page</Link> and
                send us a message!
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Startpage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    smoke: imageSharp(sizes: { originalName: { eq: "smoke.png" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export default IndexPage
