import React, { useEffect, useRef, useState } from 'react';
import { ArrowDown, Logo } from 'components/svg';
import TitleAnimation from 'components/titleAnimation';
import { useSpring, config, animated } from 'react-spring/web.cjs';
import useVisibility from 'utils/useVisibility';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import StickyMenu from './sticky-menu';
import { useLocation } from "@reach/router";
import * as easings from "d3-ease";
import { debounce, isMobileOrTablet } from 'utils/helpers';

const StartHero = () => {
  const [visible, visibleRef] = useVisibility<HTMLDivElement>(300, 50, true);
  const heroRef = useRef<HTMLDivElement>();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(!location.hash);
  const [showArrow, setShowArrow] = useState<boolean>(false);

  const {heroBgDesktop, heroBgMobile} = useStaticQuery(graphql`
    query StartHeroQuery {
      heroBgDesktop: imageSharp(sizes: {originalName: {eq: "loading-splash.png"}}) {
        fluid(maxWidth: 2000, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      heroBgMobile: imageSharp(sizes: {originalName: {eq: "loading-splash-mobile.png"}}) {
        fluid(maxWidth: 600, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroBg = [
    heroBgMobile.fluid,
    {
      ...heroBgDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const loadingScreen = useSpring({
    opacity: loading ? 1 : 0,
    zIndex: loading ? 2000 : -1,
    config: config.molasses
  });

  const imgLoaded = useSpring({
    transform: loading ? 'scale(1.2)' : 'scale(1)',
    config: {
      duration: 7000,
      easing: easings.easeCubicOut
    }
  });

  const arrowAnim = useSpring({
    opacity: showArrow ? 1 : 0,
    transform: showArrow ? 'translate3d(0,0,0)' : 'translate3d(0,-20px,0)',
    config: config.wobbly
  });

  const [{y}, setY] = useSpring<{y: number}>(() => ({
    y: 0,
    immediate: true,
    config: config.stiff
  }));

  const onScroll = () => {
    setY({
      y: window.scrollY * 0.12,
      immediate: true,
    });
  };

  const appHeight = debounce(() => {
    if (!isMobileOrTablet()) {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
  }, 100);
  useEffect(() => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    window.addEventListener('resize', appHeight);

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [onScroll]);

  useEffect(() => {
    const timeout1 = setTimeout(() => setLoading(false), 800);
    const timeout2 = setTimeout(() => setShowArrow(true), 6000);

    return () => {
      clearTimeout(timeout1); 
      clearTimeout(timeout2);
    };
  }, [setLoading, setShowArrow])

  const el1Props1 = useSpring({
    opacity: visible ? 1 : 0, 
    transform: visible ? 'translate3d(0,0,0)' : 'translate3d(-5px,-5px,0)',
    config: {
      ...config.molasses,
    }
  });

  const el1Props2 = useSpring({
    opacity: visible ? 1 : 0, 
    transform: visible ? 'translate3d(0,0,0)' : 'translate3d(5px,5px,0)',
    delay: 300,
    config: {
      ...config.molasses,
    }
  });

  const {t} = useTranslation('hero');

  return (
    <section ref={heroRef} className="hero relative">
      <animated.div style={loadingScreen} className="loading-screen"/>
      <StickyMenu visible={showArrow}/>
      <div className="hero-text relative z-10 mb-20 md:mb-60">
        <div className="container z-20">
          <div className="row">
            <div className="logo-col col-span-9 col-start-1 sm:col-span-8 sm:col-start-1 md:col-span-9 lg:col-span-9 md:col-start-1 mb-2 md:mb-4">
              <Logo />
            </div>
            <div className="col-span-12">
              <TitleAnimation>
                {t('animationTitle')}
              </TitleAnimation>
            </div>
            <animated.div style={arrowAnim} className="col-span-1 col-start-10 lg:col-start-11">
              <a href="#quality" title="Start ingress">
                <ArrowDown className="svg-smokeText absolute cursor-pointer z-10 h-16 mt-0 md:mt-20" style={{width: '60px'}}/>
              </a>
            </animated.div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b to-white from-whiteTrans z-10 relative">
        <div id="quality" className="container relative z-10 py-10 md:py-20">
          <div ref={visibleRef} className="grid grid-cols-12 px-5 md:px-0 mt-24">
            <animated.div style={{opacity: 0, ...el1Props1}} className="col-span-12 fade-in-el lg:col-span-8 xl:col-start-2">
              <h2 className="styled large w-ligature text-smokeBgBlack">
                {t('ingressTitle')}
              </h2>
            </animated.div>
            <animated.div style={{opacity: 1, ...el1Props2}} className="col-span-12 fade-in-el col-start-1 sm:col-span-10 sm:col-start-2 lg:col-span-9 lg:col-start-2 xl:col-span-8 xl:col-start-3 mb-14">
              <div className="ingress ingress--large font-extralight text-smokeBgBlack">
                {t('ingress')}
              </div>
            </animated.div>
          </div>
        </div>
      </div>
      <div className={"absolute w-full h-full top-0 left-0 z-0"}>
        <animated.div style={{height: '100%', willChange: 'transform', ...imgLoaded}}>
          <animated.div style={{top: 0, position: 'relative', height: '100%', width: '100%', willChange: 'transform', transform: y.interpolate((y) => (`translate3d(0,${y}px,0)`))}}>
            <Img fluid={heroBg} loading={'eager'} objectFit={'cover'} style={{height: '100%'}} objectPosition={"top"} />
          </animated.div>
        </animated.div>
      </div>
    </section>
  );
};

export default StartHero;
